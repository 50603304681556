import React, { useState, useEffect } from 'react';
import Header from './components/header/index';
import { homeRef, servicesRef, contactRef, faqsRef, productsRef, policyRef, directoryRef, aboutRef, careersRef, blogRef, newsRef, eventsRef, publicationsRef, tbn247Ref } from './components/refs';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import Contact from './components/contact/index';
import Home from './components/home/index';
import Footer from './components/footer/index';
import Services from './components/services/index';
import FAQs from './components/faqs/index';
import Products from './components/products/index';
import Policy from './components/policy/index';
import Directory from './components/directory';
import Careers from './components/careers';
import BlogPage from './components/blog';
import About from './components/about';
import EventPage from './components/event';
import TBN247 from './components/products/tbn247';
function App() {
  const [activeRef, setActiveRef] = useState(homeRef);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL contains 'policy'
    if (location.pathname.toLowerCase().includes('policy')) {
      setActiveRef(policyRef);
      navigate('/policy');
    }
    else if (location.pathname.toLowerCase().includes('directory')) {
      setActiveRef(directoryRef);
      navigate('/directory');
    }
    else if (location.pathname.toLowerCase().includes('about')) {
      setActiveRef(aboutRef);
      navigate('/about');
    }
    else if (location.pathname.toLowerCase().includes('careers')) {
      setActiveRef(careersRef);
      navigate('/careers');
    }
    else if (location.pathname.toLowerCase().includes('blog')) {
      setActiveRef(blogRef);
      navigate('/blog');
    }
    else if (location.pathname.toLowerCase().includes('news')) {
      setActiveRef(newsRef);
      navigate('/news');
    }
    else if (location.pathname.toLowerCase().includes('events')) {
      setActiveRef(eventsRef);
      navigate('/events');
    }
    else if (location.pathname.toLowerCase().includes('publications')) {
      setActiveRef(publicationsRef);
      navigate('/publications');
    } else if (location.pathname.toLowerCase().includes('products/tbn247')) {
      setActiveRef(tbn247Ref);
      navigate('/products/tbn247');
    }
    else if (location.pathname.toLowerCase().includes('contact')) {
      setActiveRef(contactRef);
      navigate('/contact');
    }
    else {
      setActiveRef(homeRef);
      navigate('/');
    }
  }, [location.pathname]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const scrollToSection = (sectionRef) => {
    setActiveRef(sectionRef);
    if(sectionRef === policyRef){
      navigate('/policy');
    } else if(sectionRef === directoryRef){
      navigate('/directory');
    }
    else if(sectionRef === aboutRef){
      navigate('/about');
    }
    else if(sectionRef === careersRef){
      navigate('/careers');
    }
    else if(sectionRef === blogRef){
      navigate('/blog');
    }
    else if(sectionRef === newsRef){
      navigate('/news');
    }
    else if(sectionRef === eventsRef){
      navigate('/events');
    }
    else if(sectionRef === publicationsRef){
      navigate('/publications');
    }
    else if(sectionRef === aboutRef){
      navigate('/about');
    } else if(sectionRef === tbn247Ref){
      navigate('/products/tbn247');
    } else if(sectionRef === contactRef){
      navigate('/contact');
    }
    else {
      navigate('/');
    }
    if (sectionRef && sectionRef.current) {
      const headerOffset = 15; // Adjust this value based on your header height
      const elementPosition = sectionRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
        });
      }
  };

  return (
    <div className={isDarkMode ? 'app dark-mode' : 'app light-mode'}>
      <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} scrollToSection={scrollToSection} activeRef={activeRef}/>
      <Routes>
        <Route path="/policy" element={<Policy ref={policyRef}/>} />
        <Route path="/" element={<div className="App">
          <Home ref={homeRef} />
            <Services ref={servicesRef}/>
            <Products ref={productsRef}/>
            <Contact ref={contactRef}/>
            <FAQs ref={faqsRef}/>
            </div>} />
        <Route path="/directory" element={<Directory ref={directoryRef}/>} />
        <Route path="/careers" element={<Careers ref={careersRef}/>} />
        <Route path="/blog" element={<BlogPage ref={blogRef}/>} />
        <Route path="/about" element={<About ref={aboutRef}/>} />
        <Route path="/events" element={<EventPage ref={eventsRef}/>} />
        <Route path="/sitemap.xml" element={<Navigate to="/sitemap.xml" replace/>} />
        {/* <Route path="/products" element={<Products/>} /> */}
        <Route path="/products/tbn247" element={<TBN247/>} />
        <Route path="/contact" element={<Contact ref={contactRef}/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;