import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faNewspaper, faDesktop, faCloud } from '@fortawesome/free-solid-svg-icons';

const TBN247 = () => {
    const productData = {
        id: 'tbn247',
        title: "TBN247 News Portal",
        description: "Premier online news portal based in South Asia, delivering real-time news and insights across various topics.",
        features: [
            { icon: faGlobe, text: "24/7 news coverage with a focus on South Asia" },
            { icon: faNewspaper, text: "Expanding into Europe with AI-driven insights" },
            { icon: faDesktop, text: "Responsive design for seamless browsing" },
            { icon: faCloud, text: "Real-time updates on regional affairs" },
        ],
        image: require('../../../icons/images/tbn247.png'),
        link: "https://tbn247.com/",
        playStore: "https://play.google.com/store/apps/details?id=com.news.ui",
        appStore: "https://apps.apple.com/us/app/tbn247-news/id6444491222"
    };

    return (
        <div className="tbn247-container">
            <h1>{productData.title}</h1>
            <img src={productData.image} alt={productData.title} className="tbn247-image" />
            <p>{productData.description}</p>
            <div className="features-list">
                {productData.features.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <FontAwesomeIcon icon={feature.icon} />
                        <span>{feature.text}</span>
                    </div>
                ))}
            </div>
            <div className="tbn247-links">
                <a href={productData.link} className="tbn247-link" target="_blank" rel="noopener noreferrer">Visit TBN247</a>
                <a href={productData.playStore} className="tbn247-link" target="_blank" rel="noopener noreferrer">Play Store</a>
                {/* <a href={productData.appStore} className="tbn247-link" target="_blank" rel="noopener noreferrer">App Store</a> */}
            </div>
        </div>
    );
};

export default TBN247;
